/**
 * 20201119 GC 190-4871 created this class for the Rest services
 * 20210105 GC 189-4864 Added new API's for login (validatePortal,getToken, logUser)
 * 20210219 RP 228-4892 remove the cmpCode from landing page API end point
 * 20210305 GC 222-4887 Added acceptTerms endpoint
 * 20210503 GC 246-4905 Added logout API endpoint
 * 20210719 SR 465-4925 Added Profile picture related endpoints
 * 20240129 SS 521 Added Portal QR Code
 * 20240401 SV XENOV2-638 MyLeave dashboard
 * 20240409 SV XENOV2-638 Add getMonthlyWiseLeaveDetails API
 * 20240422 SV XENOV2-638 Added getEmpLeaveDaysInDep, getCompanyAllLeaveDetails API endpoints
 * 20240418 SS Added a nice to have a feature to load an avatar if no profile picture is updated
 * 20240829 SV XENOV2-695 Dashboard Enhancement Frontend and UI 
 * 20240909 SS 780 Subscription module development
 * 20241218 SS 927 Added getEmployees API Implementation
 * 20241218 SS 927 Updated getLeaveStatistics API
 */

export const ENDPOINTS = {
    getOrgConfig: '/organization/landing-page', // end point for get landing page config
    acceptTerms: '/organization/accept-term-condition', // Accept terms and conditions
    validatePortal: '/organization/portal',
    getToken: '/auth/portal/login',
    logUser:'/auth/login',
    refreshToken:'/auth/portal/refresh-token',
    getEmail: '/auth/verification?token=${token}', // end point for get email
    logout:'/auth/logout',
    getCompanyQR:'/organization/getCompanyQR',
    moduleCat:'/organization/moduleCat',
    
    //Profile picture related endpoints
    getProfilePic: '/user/profile-pic/username/{username}',
    uploadProfilePic: '/user/profile-pic/username/{username}',
    getFullName: '/user/full-name/username/{username}',

    //getDepartmentLeaveDetails
    getDepartmentLeaveDetails: '/dashboard/getDepartmentLeaveDetails',

    //getAllDepartmentLeaveDetails
    getAllDepartmentLeaveDetails: '/dashboard/getAllDepartmentLeaveDetails',

    
    //getCompanyLeaveDetails
    getCompanyLeaveDetails: '/dashboard/getCompanyLeaveDetails',

    //getEmployeeLeaveDetails
    getEmployeeLeaveDetails: '/dashboard/getEmployeeLeaveDetails',

    //getMonthlyWiseLeaveDetails
    getMonthlyWiseLeaveDetails: '/dashboard/getMonthlyWiseLeaveDetails',

    //getEmpLeaveDaysInDep
    getEmpLeaveDaysInDep: '/dashboard/getEmpLeaveDaysInDep?depCode={depCode}',

    //getCompanyAllLeaveDetails
    getCompanyAllLeaveDetails: '/dashboard/getCompanyAllLeaveDetails?date={date}',

    //getDepartments
    getDepartments: '/dashboard/getDepartments',

    //getEmployees
    getEmployees: '/dashboard/getEmployees',

    //getDeptWiseLeaveCount
    getDeptWiseLeaveCount: '/dashboard/getDeptWiseLeaveCount?date={date}',

    //getLeaveStatistics
    getLeaveStatistics: '/dashboard/getLeaveStatistics?department={department}&employee={employee}',

    //getCompLeaveSummary
    getCompLeaveSummary: '/dashboard/getCompLeaveSummary?date={date}',

    //getDeptLeaveSummary
    getDeptLeaveSummary: '/dashboard/getDeptLeaveSummary?date={date}',

    //getLeaveTypeWiseCount
    getLeaveTypeWiseCount: '/dashboard/getLeaveTypeWiseCount?date={date}',

    //getLeaveTypes
    getLeaveTypes: '/dashboard/getLeaveTypes',

    //getSegments
    getSegments: '/dashboard/getSegments'
};

/**
 * 20210201 CV 206-4880 Added to block special characters
 * 20201103 122-4803 CV Added a new method called specialCharacterSetThree(event) to allow only specific character set.
 * 20201117 GC 118-4788 AddedspecialCharacterSetDefault
 * 20201211 118-4788 CV Added methods to paste special characters.
 * 20210101 226-4885 CV Added methods to avoid paste on special characters.
 * 20210120 248 CV Added method to add special characters for company bank details.
 * 20210326 GC 206-4880 changed special charactor restrict method
 * 20231107 SS XENOV2-434 5169 Added plusmarksNumericOnly and vlidateOnPasteNumericPlusMarkOnly functions
 * 20240725 SS 753-5260 Added to core service and used as a provider service via shared modules to reduced redundancy
 * 20240725 SS 753-5260 Added allowPhoneNumberOnly method which is used but never created in company genaral details
 * 20240910 SS 780 Validated Company Name
 * 20240924 HJ 779 Added method to prevent user input for time duration format HH:MM
 * 20241224 HJ 871 Fixed validation issue in onDurationInput() method
 */
import { Injectable } from '@angular/core';
@Injectable()
export class specialCharService {
  constructor() { }

  specialCharacterSetOne(event) {

    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore, dash, colon, semi-colon, ful stop, slash, hash
    if (/[a-zA-Z0-9-_#/:;. ]/.test(inp)) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

  specialCharacterSetDefault(event) {

    const inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore, dash, colon, semi-colon, ful stop, slash
    if (/[a-zA-Z0-9-_/:;. ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  specialCharacterSetTwo(event) {
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore, dash, colon, semi-colon, ful stop and comma
    if (/[a-zA-Z0-9-_:;., ]/.test(inp)) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

  specialCharacterSetThree(event) {
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore, dash, colon, semi-colon, ful stop and comma
    if (/[a-zA-Z0-9-_:;.,() ]/.test(inp)) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

  allowOnlyAlphanumeric(event) {
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets
    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

  specialCharacterSetfour(event) {
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore, dash, colon, semi-colon, ful stop and comma
    if (/[a-zA-Z0-9-_:;., ()&]/.test(inp)) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

  specialCharacterSetfive(event) {
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore, dash, slash colon, semi-colon, ful stop and comma
    if (/[a-zA-Z0-9-_:;.,/() ]/.test(inp)) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

  plusmarksNumericOnly(event) {
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, plus
    if (/[0-9+]/.test(inp)) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

  plusmarksOnly(event) {
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, plus
    if (/[a-zA-Z0-9+]/.test(inp)) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

  allowCharactersForCurrency(event) {
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, comma, full stop
    if (/[a-zA-Z0-9,.]/.test(inp)) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

  specialCharacterSetSix(event) {
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore, dash, colon, semi-colon, ful stop and comma
    if (/[a-zA-Z0-9-_:;.,()& ]/.test(inp)) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

  specialCharacterSetSeven(event) {
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets and space
    if (/[a-zA-Z0-9 ]/.test(inp)) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }



  specialCharacterSetDefaultPhaste(event) {

    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    // Allow numbers, alpahbets, space, underscore, dash, colon, semi-colon, ful stop, slash
    if (/^[a-zA-Z0-9-_/:;. ]/.test(pastedText)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }



  vlidateOnPasteSetOne(event) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    if (/^[a-zA-Z0-9-_#/:;. ]*$/.test(pastedText)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  vlidateOnPasteSetTwo(event) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    if (/^[a-zA-Z0-9-_:;., ]*$/.test(pastedText)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  vlidateOnPasteCurrency(event) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    if (/^[a-zA-Z0-9,.]*$/.test(pastedText)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  vlidateOnPastePlusMarkOnly(event) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    if (/^[a-zA-Z0-9+]*$/.test(pastedText)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  vlidateOnPasteNumericPlusMarkOnly(event) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    if (/^[0-9+]*$/.test(pastedText)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  vlidateOnPasteAllowOnlyAlphanumeric(event) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    if (/^[a-zA-Z0-9]*$/.test(pastedText)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  vlidateOnPasteSpecialCharacterSetThree(event) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    if (/^[a-zA-Z0-9-_:;.,() ]*$/.test(pastedText)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  specialCharacterSetfourPhaste(event) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    // Allow numbers, alpahbets, space, underscore, dash, colon, semi-colon, ful stop and comma
    if (/^[a-zA-Z0-9-_:;., ()&]/.test(pastedText)) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

  vlidateOnPasteSpecialCharacterSetFive(event) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    if (/^[a-zA-Z0-9-_:/;.,() ]*$/.test(pastedText)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  vlidateOnPasteSetSix(event) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    if (/^[a-zA-Z0-9-_:;.,()& ]*$/.test(pastedText)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  validateOnPasteSetSeven(event) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    // Allow numbers, alpahbets and space
    if (/^[a-zA-Z0-9 ]+$/.test(pastedText)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  allowPhoneNumberOnly(event) {
    const inp = String.fromCharCode(event.keyCode);
    // Allow numbers and plus
    if (/[0-9+]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  onDurationInput(event: any) {
    let value = event.target.value.replace(/[^0-9]/g, ''); // Allow only digits

    // Allow field to be cleared
    if (value.length === 0) {
      event.target.value = '';
      return;
    }

  // Handle input deletion (Backspace)
  if (event.inputType === 'deleteContentBackward') {
    if (value.length <= 2) {
      event.target.value = value; // Remove colon when only hours remain
    } else {
      event.target.value = value.slice(0, 2) + ':' + value.slice(2); // Reapply colon
    }
    return;
  }


    // Only allow up to 4 digits (HHMM)
    if (value.length > 4) {
      value = value.slice(0, 4); // Trim extra digits
    }

    // Restrict the first digit (hours) to 0, 1, or 2
    if (value.length >= 1 && !/[0-2]/.test(value[0])) {
      value = '';  // Reset if the first digit is invalid
    }

    // Restrict the second digit (hours)
    if (value.length >= 2) {
      const firstDigit = value[0];
      const secondDigit = value[1];

      if (firstDigit === '2' && !/[0-3]/.test(secondDigit)) {
        value = firstDigit;  // Reset the second digit if it exceeds 23
      }
    }

    // Automatically insert colon after two digits (HH:MM)
    if (value.length >= 2) {
      value = value.slice(0, 2) + ':' + value.slice(2);
    }

    // Restrict the first digit (minutes) to 0-5
    if (value.length >= 4 && !/[0-5]/.test(value[3])) {
      value = value.slice(0, 3);  // Reset the minute digit if it exceeds 59
    }
    // Set the formatted value back to the input field
    event.target.value = value;
  }

  capitalizeWords(str: string): string {
    if (!str) return ''; // Handle null or empty strings

    return str
      .split(' ') // Split the string into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter and lowercase the rest
      .join(' '); // Join the words back into a single string
  }



  formatDateToYYYYMMDD(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }


  // Method to format a date to a string like "5th October"
  formatDateToString(date: Date): string {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' }); // Get month name (e.g., October)
    const suffix = this.getDaySuffix(day); // Get the suffix for the day (e.g., "st", "nd", "rd", "th")
    return `${day}${suffix} ${month}`;
  }

  // Method to get the suffix for the date (st, nd, rd, th)
  getDaySuffix(day: number): string {
    if (day > 3 && day < 21) {
      return 'th';
    }
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

}
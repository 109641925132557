/**
 * 20201119 GC 190-4871 Addeed CoreApiService
 * 20210202 CV 208-4891 Added API service.
 * 20231030 SV XENOV2-434 : Employee management - Add employee direct input
 * 20231025 HP XENOV2-438-5167 : Leave Management UI development.
 * 20231030 SV XENOV2-436 : Leave configuration development
 */

import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { MainRouterModule } from './core/router/main-router.module';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from './core/module/core.module';
import { SharedModule } from './core/module/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MainMenuItems } from './core/models/main-menu-items';
import { MainMenuComponent } from './core/components/create-menu/create-menu.component';
import { LoadingScreenComponent } from './modules/loading-screen/loading-screen.component';
import { LoadingScreenInterceptor } from './helpers/loading.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreApiService } from './core/services/api.service';
import { ApiService } from '../app/modules/login/services/api.service';
import { MatCheckboxModule } from '@angular/material';

@NgModule({
  declarations: [
    AppComponent, 
    MainMenuComponent, 
    LoadingScreenComponent,
  ],
  imports: [
    CoreModule,
    SharedModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    MainRouterModule,
    MatTooltipModule,
    MatCheckboxModule,
  ],
  providers: [MainMenuItems, CoreApiService, ApiService,{
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingScreenInterceptor,
    multi: true
  }],
  entryComponents: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

/**
 * 20240401 SV XENOV2-638 MyLeave dashboard
 * 20240906 SV XENOV2-780 subscription module changes
 */

import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { UserService } from '../services/user.service';
import { MatProgressBarModule, MatSlideToggleModule } from '@angular/material';
import { ChartsModule, ThemeService } from 'ng2-charts';


@NgModule({
    declarations: [],
    imports: [
        MatProgressBarModule,
        ChartsModule,
    ],
    providers: [
        AuthenticationService,
        UserService,
        ThemeService,
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() core: CoreModule) {
        if (core) {
            throw new Error('You shall not run!');
        }
    }
}
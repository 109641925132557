/**
 * 20210621 SR 160-4916 Manage event type related services added
 * 20210615 SR 265-4921 Added methods related to add event screen
 * 20241002 SV XENOV2-805-5312 Add Event Categories
 * 20241001 SV XENOV2-801 Add Events
 * 20241014 HJ XENOV2-806 Implementation of edit event category
 * 20241015 HJ XENOV2-807-5323 Added methods related to assign employees to an event
 * 20241023 HJ XENOV2-802-5332 Added methods related to edit event
 */

import { Injectable } from "@angular/core";
import { HttpService } from '../../../core/http/http.service';
import { appConfig } from '../../../../environments/environment';
import { ENDPOINTS } from "../util/api-endpoints";
import { Observable } from "rxjs";

@Injectable()
export class CalanderEventService{
    constructor (private httpService: HttpService){
    }
    
    getEventTypes(status){
        const url = appConfig.appRoot.concat(ENDPOINTS.getEventTypesByStatus).replace('{status}', status); 
        return this.httpService.httpGet(url);
    }

    getDepartments(){
        const url = appConfig.appRoot.concat(ENDPOINTS.getDepartments).replace('{status}', '');
        return this.httpService.httpGet(url);
    }
    getSubDepartments(){
        const url = appConfig.appRoot.concat(ENDPOINTS.getSubDepartments).replace('{status}', '');
        return this.httpService.httpGet(url);
    }

    getSections(){
        const url = appConfig.appRoot.concat(ENDPOINTS.getSections);
        return this.httpService.httpGet(url);
    }

    addCompanyEvent(body){
        const url = appConfig.appRoot.concat(ENDPOINTS.addCompanyEvent);
        return this.httpService.httpPost(url, body);
    }

    addEventCategory(body){
        const url = appConfig.appRoot.concat(ENDPOINTS.addEventCategory);
        return this.httpService.httpPost(url, body);
    }

    checkCatNameExists(name: string){
        const url = appConfig.appRoot.concat(ENDPOINTS.checkCatNameExists).replace('{name}', name);
        return this.httpService.httpGet(url);
    }

    eventOverlap(eventOverlapReq: FormData) {
        const searchParams = new URLSearchParams();
        eventOverlapReq.forEach((value, key) => {
            const stringValue = typeof value === 'object' ? JSON.stringify(value) : value.toString();
            searchParams.set(key, stringValue);
        });
        const url = appConfig.appRoot.concat(ENDPOINTS.eventOverlap) + '?' + searchParams.toString();
        return this.httpService.httpGet(url);
    }

    editEventCategory(body){
        const url = appConfig.appRoot.concat(ENDPOINTS.addEventCategory);
        return this.httpService.httpPut(url, body);
    }

    getEventFilters(): Observable<any> {
        const url = appConfig.appRoot.concat(ENDPOINTS.getEventFilters);
        return this.httpService.httpGet(url);
    }

    getEmployeesByEvent(eventCode: number) {
        const url = appConfig.appRoot.concat(ENDPOINTS.getEmployeesByEvent).replace('{eventCode}', eventCode.toString());
        return this.httpService.httpGet(url);
    }

    updateEventEmployees(body: any) {
        const url = appConfig.appRoot.concat(ENDPOINTS.updateEventEmployees);
        return this.httpService.httpPut(url, body);
    }

    editEvent(body){
        const url = appConfig.appRoot.concat(ENDPOINTS.addCompanyEvent);
        return this.httpService.httpPut(url, body);
    }
   
}
/*
 * 20240906 SV XENOV2-780 subscription module changes
 * 20240909 SS 780 Subscription module development
 */

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-subscription-popup',
  templateUrl: './subscription-popup.component.html',
  styleUrls: ['./subscription-popup.component.css']
})
export class SubscriptionPopupComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
  }

  getModuleStyle(color: string) {
      return {
      'background-color': `${color}20`,
      'border': `1px solid ${color}40`
      };
  }

}

/**
 * 20200721 GC 166-4840 created to keep organization API services endpoints.
 * 20201218 CV 206-4880 Added API end points for cost center.
 * 20210304 GC 206-4880 removed cmpCode from cost center API's
 * 20200929 RP 126-4845 add this class for the api end points
 * 20201002 GC 168-4847 Added updateOrganization
 * 20201014 CV 203-4872 Added API end points for sub department.
 * 20201014 CV 218-4874 Added API end points for edit sub department.
 * 20201021 CV 204-4875 Added API end points for section.
 * 20201026 CV 205-4877 Added edit section API services endpoints.
 * 20201027 CV 202-4866 Added departments end points.
 * 20201218 PU 204-4875 refactor getSubDepartment and getSection endpoints
 * 20210218 RP 228-4892 removed the cmp code and user from the requests
 * 20210202 RP 253-4908 remove the getCountries API end point
 * 20210324 PU 189-4864 fixed xeno-389, updated urls: getDesignation, getDepartment, getSubDepartment, getSection
 * 20210401 GC 206-4880 Added check existing API endpoint
 * 20210514 SR 436-4920 get industry list added
 * 20210503 SR 265 4921 Add Calendar Event end points added
 * 20210604 GC 195 4922 Added manage event endpoints
 * 20210604 GC 196-4915 Added calendar view related API Endpoints
 * 20210606 GC 179 4924 Added endpoints for edit event
 * 20210621 SR 160-4916 Manage event type related eps added
 * 20210629 HA 313-4934 Added edit regulations related API Endpoints.
 * 20210708 SR 264-4931 Manage Regulations related API Endpoints
 * 20210707 SR 183-4929 Add Regulation endpoint added
 * 20210712 RP 493-4937 added the shift related API end points
 * 20210727 RP 500-4938 added the manage shift related API end points
 * 20210802 PU 506-4940 added getShift, editCompShiftBasicDtl api end point
 * 20220131 HA 264-4931 added setDefaultRegan end point.
 * 20231108 SS XENOV2-432 5172 Added User Role Management related API endpoints
 * 20231115 SS 447-5171 align to t:COUNTRY changes
 * 20240913 HJ XENOV2-782-5286 Added Working Day configuration related API endpoints
 * 20240918 HJ XENOV2-782-5286 Modified Working Day configuration related API endpoints
 * 20240925 SS 799-5301 Changed existing fields accoring to the new reuirements. View Event Calendar
 * 20241002 SV XENOV2-805-5312 Add Event Categories
 * 20241001 SV XENOV2-801 Add Events 
 * 20241015 HJ XENOV2-807-5323 Added Assign employees to an event related API endpoints
 */

export const ENDPOINTS = {
    getCompanyDetails: '/organization/details', //228-4892-RP remove cmpCode
    getTimezones: '/master-data/timezones',
    getCities: '/master-data/cities/cntryCode/{cntryCode}',
    updateOrganization: '/organization/details',
    saveSignUp: '/organization/company-sign-up', // api end point for the save company sign up
    saveDesignation: '/organization/designation', // save designation end point
    /**
     * API end point to get designation list,
     * 228-4892-RP remove the cmpCode
     */
    getDesignations : '/employee/designations',

    /**
     * API end point to get designation details
     * 228-4892-RP remove the cmpCode
     */
    getDesignation : '/organization/designation?desName=${name}',

    updateDesignation : '/organization/designation', // Update designation
    saveDepartment: '/organization/department',
    getDepartments : '/employee/departments', //remove the cmpCode
    /**
     * get specified department details API end point
     * 228-4892-RP remove the cmpCode
     */
    getDepartment : '/organization/department?deptName=${deptName}',

    updateDepartment: '/organization/department',

    // sub-department details api end points
    getSubDepartments: '/employee/sub-departments', // remove the cmpCode
    saveSubDepartment: '/organization/sub-department',

    /**
     * get specified sub-department details API end point
     * 228-4892-RP remove the cmpCode
     */
    getSubDepartment: '/organization/sub-department?deptCode={deptCode}&subDeptName={subDeptName}',

    /**
     * get the parent department details
     * 228-4892-RP remove the cmpCode
     */
    getParentDepartment: '/employee/departments?status={status}',

    updateSubDepartment: '/organization/sub-department',

    /**
     * The API endpoint to get the company logo
     * 228-4892-RP remove the cmpCode
     */
    updateCompanyLogo: '/organization/logo',
    getSections: '/employee/sections',
    saveSection: '/organization/section',

    /**
     * The API endpoint to get the company sections details
     * 228-4892-RP remove the cmpCode
     */
    getSection: '/organization/section?secName={secName}&subDeptCode={subDeptCode}&deptCode={deptCode}',

    /**
     * The API endpoint to get the company sub departments details
     * 228-4892-RP remove the cmpCode
     */
    getParentSubDepartment: '/employee/sub-departments?status={status}',

    updateSection: '/organization/section',

    saveCostCenter: '/organization/cost-centers',
    getParentSection: '/employee/sections?status={status}',
    getCostCenters: '/organization/cost-centers',
    getCostCenter: '/organization/cost-center/cosCode/{cosCode}',
    checkExisting: '/organization/cost-center?name={cosName}',

    /**
     * get industries
     */
     getIndustries: '/organization/industries',
     /*

    /**
     * Calendar event related API endpoints
     */
    getEvents: '/organization/events',
    getEvent: '/organization/event?eventCode={eventCode}',
    getEventTypes: '/organization/event-types',
    getEventTypesByStatus: '/organization/event-types?status={status}',
    manageEventTypes: '/organization/event-types',
    addCompanyEvent: '/organization/event',
    addEventCategory: '/organization/event-type',
    checkEventTypeValidity: '/organization/event-name-exist?name={eventName}',
    getActiveEventTypes: '/organization/event-types?status=active&orderBy={orderBy}',
    checkCatNameExists: '/organization/event-type-name-exist?name={name}', 
    eventOverlap: '/organization/event-overlap',
    // New endpoints related to assign employees to an event
    getEmployeesByEvent: '/organization/event-employees?eventCode={eventCode}',
    getEventFilters: '/organization/event-filters',
    updateEventEmployees: '/organization/event-employees',

    /**
     * Regulation related API endpoints
     */
     getWorkBasis:'/master-data/work-basis',
     getSelectedRegulation: '/regulation/company/regulation?code={reganCode}',
     checkExistingReganId: '/regulation/company/check-regan-id-unique?id={id}',
     checkExistingReganName: '/regulation/company/check-regan-name-unique?name={name}',
     addCompanyRegulation: '/regulation/company/regulation',
     updateCompRegan: '/regulation/company/regulation',
     getStates: '/master-data/states?countrySymbol={cntryCode}',
     getStatutoryRegulations: '/master-data/regulations',
     getStatutoryRegulation: '/master-data/regulation?code={reganCode}',
     getCompanyRegulations: '/regulation/company/regulations',
     addToCompReg: '/regulation/company/regulation-from-statutory',
     removeCompReg: '/regulation/company/regulation?compReganCode={reganCode}',
     defaultRegulation: '/master-data/def-regulation',
     setDefaultRegan: '/regulation/company/set-default',
    /**
     * Shift related API endpoints
     */
    addShiftBasicDetails: '/payroll-shift/shift-basic-detail',
    addShiftBreakDetails: '/payroll-shift/shift-break-details',
    payRateDetails: '/payroll/pay-rate',
    addShiftOtDetails: '/payroll-shift/shift-ot-details',
    masterDefShiftDtl: '/payroll-shift/default-shifts',
    updAllShiftDtl: '/payroll-shift/shift-all-details',
    saveAllShiftDtl: '/payroll-shift/shift-all-details',
    updShiftBasicDetails: '/payroll-shift/shift-basic-detail',
    getShift: '/payroll-shift/shift?code=${code}',
    editCompanyShift: '/payroll-shift/shift-basic-detail',

     /**
      * Mange shift related API end points
      */
    getShiftTemplates: '/master-data/shifts',
    getCompShifts: '/payroll-shift/shifts',
    addTempShiftForComp: '/payroll-shift/shift',
    delCompShiftByCode: '/payroll-shift/shift/{shiftCode}',
    setDefaultShift: '/payroll-shift/shift-default/{code}',
    getTempShiftByCode: '/master-data/shift/{code}',

     /**
      * Shift related API endpoints
      */
     checkNameIsExist: '/payroll-shift/shift-name-exist?name=${name}',
     checkIdIsExist: '/payroll-shift/shift-id-exist?id=${id}',
     addCompanyShifts: '/payroll-shift/shift-basic-detail',

    /*
     * 20231108 SS XENOV2-432 5172 Added User Role Management related API endpoints
     *
     * User Role Management related API endpoints
     */
    getUserRoles: '/organization/getUserRoles',
    updateUserRoles: '/organization/updateUserRoles',
    
    // New endpoints for user roles
    getUserRoleList: '/user-roles/getUserRolesByCompany', // new end point to get user roles
    createUserRoles: '/user-roles/addUserRole',
    updateRole: '/user-roles/editUserRole',
    getEmployeesByRole: '/user-roles/getEmployees',
    getPermissions:'/user-roles/getPermission',
    updateUsersRole: '/user-roles/assignUserRoleToEmp',
    
    /**
     * 447-5171 changes to the t:COUNTRY related APIs
     */
    getCountries: '/master-data/countries',

    // New endpoints for working day configuration
    getWorkingDays: '/organization/getWorkingDay', // new end point to get working days
    createWorkingDay: '/organization/addWorkingDay',
    updateWorkingDay: '/organization/editWorkingDay',

};

/**
 * 20201117 GC 191-4873  added comments
 * 20201125 GC 191-4873 Added checkbox behaviours
 * 20201130 GC 191-4873 Added onElementScroll(), onLoadTerms()
 * 20210101 GC 222-4887 Added acceptTerms(), show() methods
 * 20210104 GC 222-4887 Added navigateToLogin(), clearSession() methods
 * 20210219 PU 291-4911 added common error handling
 * 20231215 SS XENOV2-425 error notification without routing to a page
 * 20240806 SS Removed the pdf viewer and developed the logic
 * 20240906 SV XENOV2-780 subscription module changes
 * 20240909 SS 780 Subscription module development
 * 20240910 SS 780 Validated Company Name
 */

import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreApiService } from '../../services/api.service';
import { DOCUMENT } from '@angular/common';
import { NotificationUtils } from 'src/app/core/util/notification-util';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { MatDialog } from '@angular/material';
import { SubscriptionPopupComponent } from './subscription-popup/subscription-popup.component';

@Component({
    selector: 'app-subscription',
    templateUrl: './subscription.component.html',
    styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {
    subscriptionData: any[] = [];
    selectedPackage = null;
    termsForm: FormGroup;
    termsApproved = false;
    numberOfPages = 0;
    showModal: boolean;
    currentUser = localStorage.getItem('currentUser');
    responseData;
    termsCompleted; // parameter to show hide pdf
    // Use ViewChild to get a reference to the scrollable div
    @ViewChild('scrollableDiv', { static: true }) scrollableDiv: ElementRef;

    constructor(
        private formBuilder: FormBuilder,
        private apiService: CoreApiService,
        @Inject(DOCUMENT) private document: Document,
        private notificationUtils: NotificationUtils,
        private authenticationService: AuthenticationService,
        private dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.termsForm = this.formBuilder.group({ // create form
            acceptTerms: [false, Validators.requiredTrue],
            download: [false, '']
        });

        let perms = localStorage.getItem('permissions').split(',');
      
        if (perms.indexOf('VIEW_COMPANY') !== -1) {
            this.termsCompleted = true;
        }

        this.moduleCat();
    }

    getPackageStyle(color: string) {
        return {
            'border-color': color,
            'box-shadow': 'none',
        };
    }
    
    getSelectedStyle(color: string) {
        return {
            'border-color': color,
            'box-shadow': `0 0 15px ${color}`,
            'transform': 'scale(1.02)',
            'transition': 'transform 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease',
        };
    }
    
    /**
    *
    * On after load pdf, event triggers and return number of pages
    */
    onLoadTerms(event) {
        this.numberOfPages = event;
    }


    /**
    *
    * Check the value of I agree checkbox
    */
    checkValue(event) {
        if (this.termsForm.value.acceptTerms) {
            this.termsApproved = true;
        } else {
            this.termsApproved = false;

        }

    }

    /**
    * Confirm terms and conditions agreement
    */
    acceptTerms() {
        const token = JSON.parse(localStorage.getItem('user'));
        const requestBody = { 
            "token": token.token,
            "moduleCat": this.selectedPackage
        };

        if (this.termsForm.value.download) { // If user checked download checkbox
            var a = document.createElement("a"); // Create anchor tag and programatically click it for download file
            a.href = 'assets/terms_cond/terms-and-conditions.pdf';
            a.target = '_blank';
            a.download = "XENO_HRM_Terms_and_Conditions.pdf";
            a.click();
        }

        this.apiService.acceptTerms(requestBody).subscribe(
            (res) => {
                if (res.apiStatusCode === 0) { // with the response create user object with available data
                    this.responseData = res.data;
                    this.authenticationService.updatePermissions(res.additionalParams.permissions);
                    this.show();  // Show information popup message
                }
            },
            (error) => {
                this.notificationUtils.showErrorMessage(error.errorMessage, error.data);
            }
        );
    }

    moduleCat() {
        this.apiService.moduleCat().subscribe(
            (response) => {
                if (response.httpStatusCode === 200) {
                    this.subscriptionData = response.data;
                    this.getSelectedPackage(response.data);
                }
            },
            (error) => {
                this.notificationUtils.showErrorMessage(error.errorMessage, error.data);
            }
        );
    }

    getSelectedPackage(packageData: any[]) {
        this.selectedPackage = packageData[0].moduleCatCode;
    }

    /**
     * show event for information popup
     */
    show() {
        this.showModal = true; // Show-Hide Modal Check
    }

    /**
     * Clear local storage and move to portal login page
     */
    navigateToLogin(){
        this.clearSession();
        this.document.location.href = 'http://'+this.responseData.portalName; // redirect to portal login
    }

    /**
     * clear user from local storage
     */
    clearSession(){
        localStorage.removeItem('currentUser');
        localStorage.removeItem('user');
        localStorage.removeItem('permissions');
    }

    openSubscriptionModal(moduleCat: any){
        const dialogRef = this.dialog.open(SubscriptionPopupComponent, {
            width: '600px',
            disableClose: true,
            data: { moduleCat: moduleCat },
        });
    } 
}

/**
 * 20200916 CV 200-4862 Added mat dialog module
 * 20201011 RP 126-4845 Add OrganizationInitialSetupComponent, VerificationMessageComponent to import
 * 20201021 RP 126-4845 remove the VerificationMessageComponent from the import
 * 20201005 GC 168-4847 Added NgxMatSelectSearchModule
 * 20201013 GC 173-4850 added ErrorPageService
 * 20201021 RP 126-4845 remove the VerificationMessageComponent from the import
 * 20200911 HA 194-4859 added AvatarModule to add profile picture.
 * 20201113 GC 170-4843 Merged latest of shared modules
 * 20201119 GC 190-4871 added changed guest-dashbord as landing-page
 * 20201130 GC 191-4873 Added PdfJsViewerModule
 * 20210121 GC 222-4887 Added SettingsComponent
 * 20210202 RP 253-4908 added country services
 * 20210218 PU 291-4911 moved ErrorComponent to core module
 * 20210201 CV 208-4891 Added forgot and reset password components to import.
 * 20210218 PU 291-4911 moved ErrorComponent to core module
 * 20210504 GC 242-4906 Made date range picker shared
 * 20210616 SR 265-4921 added CalanderEventService
 * 20210621 SR 160-4916 Calender service added
 * 20210715 SR 160-4916 Manage Event Types added
 * 20210719 SR 465-4925 Added Profile picture related endpoints
 * 20210811 SR 669-4955 MatDialogRef added
 * 20240116 SS 436-5195 Removed LeaveManagementModule
 * 20240307 SV XENOV2-640 Added Terms and Conditions page and Privacy Policy page
 * 20240401 SV XENOV2-638 MyLeave dashboard
 * 20240503 DG XENOV2-677-5229 Added ConfirmationWithReasonDialog
 * 20240725 SS 753-5260 Added specialChar service as a shared service to be used in other modules
 * 20240906 SV XENOV2-780 subscription module changes
 */

import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FullLayoutComponent } from '../layout/full-layout/full-layout.component';
import { DashboardComponent } from '../layout/dashboard/dashboard.component';
import { LoginComponent } from '../../modules/login';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MenuItems } from '../models/menu-item';
import { JwtInterceptor } from '../interceptors/jwt.Interceptor';
import { ErrorInterceptor } from '../interceptors/error.interceptor';
import { fakeBackendProvider } from '../interceptors/fake-backend';
import { MatTableModule, MatPaginatorModule, MatSortModule, MatFormFieldModule, MatInputModule,
    MatCardModule, MatSelectModule, MatButtonModule, MatDatepickerModule, MatStepperModule,
    MatNativeDateModule, MatCheckboxModule, MatProgressBarModule, 
    MatSlideToggleModule} from '@angular/material';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { HasPermissionDirective } from '../permissions/has-permission.directive';
import { HttpService } from '../http/http.service';
import { AuthGuardService } from '../guards/services/auth.guard.service';
import { NotificationUtils } from '../util/notification-util';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationComponent } from '../components/confirmation.component';
import { CanDeactivateGuard } from '../guards/can-deactivate-guard';
import { UnsavedDetectService } from '../services/unsaved-detect.service';
import { MainMenuSelectService } from '../services/main-menu-select.service';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmationDialogComponent } from '../util/confirmation-dialog/confirmation-dialog.component';
import { MatTooltipModule} from '@angular/material/tooltip';
import { MatIconModule} from '@angular/material/icon';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { ChartsModule } from 'ng2-charts';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatExpansionModule} from '@angular/material/expansion';
import { OrganizationInitialSetupComponent } from 'src/app/modules/organization/components/organization-initial-setup/organization-initial-setup.component';
import { SignupComponent } from 'src/app/modules/signup/components/signup/signup.component';
import { OrgApiService } from 'src/app/modules/organization/services/org-api-service';
import { ApiService } from 'src/app/modules/signup/services/api.service';
import { ErrorComponent } from 'src/app/core/components/error-message/error-message.component'
import { LandingPageComponent} from 'src/app/core/layout/landing-page/landing-page.component';
import { ErrorPageService } from '../../core/services/error-page.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CommonUtils } from '../util/common-utils';
import { AvatarModule } from 'ngx-avatar';
import { SubscriptionComponent } from 'src/app/core/components/subscription/subscription.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { SettingsComponent } from 'src/app/core/components/settings/settings.component';
import { DoForgotpwdComponent } from 'src/app/modules/login/components/do-forgotpwd/do-forgotpwd.component';
import { DoResetpwdComponent } from 'src/app/modules/login/components/do-resetpwd/do-resetpwd.component';
import { StatDataService } from 'src/app/modules/organization/services/static-data.service';
import { CalanderEventService } from 'src/app/modules/organization/services/calander-event-service';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { ProfilePictureService } from 'src/app/modules/employee/services/profile-picture.service';
import {ProfilePictureModalsComponent} from 'src/app/core/layout/full-layout/modals/profile-picture-modals.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PrivacyPolicyComponent } from 'src/app/modules/login/components/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from 'src/app/modules/login/components/terms-conditions/terms-conditions.component';
import { CommonDashboardComponent } from '../components/common-dashboard/common-dashboard.component';
import { SummaryChartModalComponent } from '../components/common-dashboard/summary-chart-modal/summary-chart-modal.component';
import { ConfirmationWithReasonDialog } from '../util/confirmation-with-reason-dialog/confirmation-with-reason-dialog.component';
import { specialCharService } from '../services/specialChar.service';
import { SubscriptionPopupComponent } from '../components/subscription/subscription-popup/subscription-popup.component';

@NgModule({
    declarations: [
        FullLayoutComponent,
        DashboardComponent,
        LoginComponent,
        HasPermissionDirective,
        ConfirmationComponent,
        ConfirmationDialogComponent,
        OrganizationInitialSetupComponent,
        SignupComponent,
        ErrorComponent,
        SubscriptionComponent,
        LandingPageComponent,
        SettingsComponent,
        DoForgotpwdComponent,
        DoResetpwdComponent,
        ProfilePictureModalsComponent,
        PrivacyPolicyComponent,
        TermsConditionsComponent,
        CommonDashboardComponent,
        SummaryChartModalComponent,
        ConfirmationWithReasonDialog,
        SubscriptionPopupComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatSelectModule,
        MatButtonModule,
        MatDatepickerModule,
        MatStepperModule,
        MatNativeDateModule,
        NgbModalModule,
        ToastrModule.forRoot(), // ToastrModule added
        MatCheckboxModule,
        SatDatepickerModule,
        SatNativeDateModule,
        BsDatepickerModule.forRoot(),
        DatepickerModule.forRoot(),
        ChartsModule,
        NgxMatSelectSearchModule,
        MatTooltipModule,
        AvatarModule,
        PdfJsViewerModule,
        ImageCropperModule,
        NgbModule,
        MatDialogModule,
        NgxMaterialTimepickerModule,
        MatProgressBarModule,
        MatSlideToggleModule,
    ],
    exports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        ReactiveFormsModule,
        FullLayoutComponent,
        DashboardComponent,
        LoginComponent,
        FormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatSelectModule,
        MatButtonModule,
        HasPermissionDirective,
        MatDatepickerModule,
        MatStepperModule,
        MatNativeDateModule,
        MatCheckboxModule,
        MatNativeDateModule,
        MatDialogModule,
        MatTooltipModule,
        MatIconModule,
        BsDatepickerModule,
        MatAutocompleteModule,
        MatExpansionModule,
        NgxMatSelectSearchModule,
        AvatarModule,
        SubscriptionComponent,
        LandingPageComponent,
        PdfJsViewerModule,
        SettingsComponent,
        NgxMaterialTimepickerModule,
    ],
    entryComponents: [
        ConfirmationComponent, 
        ConfirmationDialogComponent, 
        ProfilePictureModalsComponent, 
        SummaryChartModalComponent, 
        ConfirmationWithReasonDialog, 
        SubscriptionPopupComponent,]
    /* NO PROVIDERS HERE! */
})
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                /* ALL OF YOUR SERVICES HERE! */
                MenuItems,
                { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
                { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
                // provider used to create fake backend
                fakeBackendProvider,
                HttpService,
                AuthGuardService,
                NotificationUtils,
                CanDeactivateGuard,
                UnsavedDetectService,
                MainMenuSelectService,
                OrgApiService,
                ApiService,
                ErrorPageService,
                CommonUtils,
                StatDataService,
                CalanderEventService,
                ProfilePictureService,
                specialCharService,
                {
                    provide: MatDialogRef,
                    useValue: {}
                },
            ]
        }
    }
}
